
import { createI18n } from 'vue-i18n';
import zh from './zh.json'
import en from './en.json'
import russia from './russia.json'

let language = navigator.language || navigator.userLanguage; 
let localeValue = "";
if(language == 'zh-CN'){
	localeValue = 'zh'
} else if(language == 'en'){
	localeValue = 'en'
} else if(language == 'ru'){
	localeValue = 'russia'
}

const i18n = createI18n({
  locale: localeValue,
	legacy: false,
  messages: { 
		"zh" : zh,
		"en" : en,
		"russia" : russia
	},
	silentTranslationWarn: true,
});

export default i18n
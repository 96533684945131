<template>
  <router-view></router-view>
</template>


<script setup>

</script>


<style>

/* @font-face {
  font-family: "HarmonyOS";
  src: url(https://oss-web.lotuscars.com.cn/sitecore/dist/static/web-fonts/harmonyos_sans_sc_light.woff);
  local("HarmonyOS Sans SC Light"),  format("woff")
} */

*{
  padding: 0;
  margin: 0;
  /* font-family: 'Arial', 'Helvetica Neue', 'PingFangBold', 'Aeroport'; */
  /* font-family: "HarmonyOS"; */
  word-wrap: break-word;
  word-break: break-all;
}

@font-face{
	font-family: "PingFangBold";
	src: url("@/assets/ttf/PingFang Bold.ttf");
}




/* ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.15);
  transition: color 0.2s ease;
} */
</style>

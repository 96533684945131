<template>
  <div class="goods-image">
    <!-- 预览大图 -->
    <div
      v-if="images.length > 0"
      class="large"
      :style="[{ backgroundImage: `url(${images[0][currIndex].img})` }, bgPosition]"
      v-show="isShow">
    </div>
    <div class="middle" ref="target">
      <!-- 左侧的大图 -->
      <img v-if="images.length > 0" :src="images[0][currIndex].img" alt="" class="imageLayer"/>
      <!-- 遮罩层 -->
      <div class="layer" :style="[position]" v-show="isShow"></div>
    </div>
    <el-carousel :loop="true" :autoplay="false" height="100px">
      <el-carousel-item
        class="el-car-item"
        v-for="(list, index) in images"
        :key="index"
      >
        <div class="el-car-itembox">
          <div v-for="(sublist, subindex) in list" :key="subindex" class="el-car-itembox-img" >
            <img @mouseenter="updateCurrIndex(subindex)" :src="sublist.img" />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { ref, watch, reactive } from "vue";
import { useMouseInElement } from "@vueuse/core";

export default {
  name: "EnlargeImages",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const dataList = ref([
      []
    ]);
    const currIndex = ref(0);
    const target = ref(null);
    const isShow = ref(false);
    // 遮罩层的坐标
    const position = reactive({
      left: 0,
      top: 0,
    });
    // 控制背景图的位置
    const bgPosition = reactive({
      backgroundPositionX: 0,
      backgroundPositionY: 0,
    });
    const { elementX, elementY, isOutside } = useMouseInElement(target);
    // 侦听鼠标移动后信息
    watch([elementX, elementY, isOutside], () => {
      // // 每次有值发生变化，就读取新的数据即可
      // isShow.value = !isOutside.value;
      // // 鼠标在图片的区域之外，不需要计算坐标
      // if (isOutside.value) return;
      // // 水平方向
      // if (elementX.value < 100) {
      //   // 左边界
      //   position.left = 0;
      // } else if (elementX.value > 560) {
      //   // 右边界
      //   position.left = 420;
      // } else {
      //   // 中间的状态
      //   position.left = elementX.value - 100;
      // }
      // // 垂直方向
      // if (elementY.value < 100) {
      //   // 上边界
      //   position.top = 0;
      // } else if (elementY.value > 380) {
      //   // 下边界
      //   position.top = 280;
      // } else {
      //   // 中间的状态
      //   position.top = elementY.value - 100;
      // }
      // // 计算预览大图背景的位置
      // bgPosition.backgroundPositionX = -position.left * 2 + "px";
      // bgPosition.backgroundPositionY = -position.top * 2 + "px";
      // // 计算左侧遮罩层位置
      // position.left += "px";
      // position.top += "px";

      isShow.value = !isOutside.value;
      if (isOutside.value) return;

      // 容器尺寸常量
      const middleWidth = 420;   // 中间图片容器宽度
      const middleHeight = 420;  // 中间图片容器高度
      const layerWidth = 200;    // 遮罩层宽度
      const layerHeight = 200;   // 遮罩层高度
      const largeWidth = 600;    // 大图容器宽度
      const largeHeight = 600;   // 大图容器高度
      const scale = 2;  // 计算实际放大倍数（3倍）

      // 计算遮罩层的最大移动范围
      const maxX = middleWidth - layerWidth;
      const maxY = middleHeight - layerHeight;

      // 计算鼠标相对位置并限制遮罩层范围
      position.left = Math.min(
        Math.max(0, elementX.value - layerWidth / 2),
        maxX
      );
      position.top = Math.min(
        Math.max(0, elementY.value - layerHeight / 2),
        maxY
      );

      // 计算背景图的位置比例
      const moveX = position.left / maxX;
      const moveY = position.top / maxY;

      // 计算背景图的最大偏移量
      const bgMaxX = middleWidth * scale - largeWidth;
      const bgMaxY = middleHeight * scale - largeHeight;

      // 根据比例计算背景位置
      bgPosition.backgroundPositionX = `${-moveX * bgMaxX}px`;
      bgPosition.backgroundPositionY = `${-moveY * bgMaxY}px`;

      // 添加单位
      position.left += "px";
      position.top += "px";
      
    });
    const updateCurrIndex = (subindex) => {
      currIndex.value = subindex;
    };
    return { currIndex, target, isShow, position, bgPosition, dataList,updateCurrIndex };
  },
};
</script>
<style scoped lang="scss">
.goods-image {
  box-sizing: border-box;
  position: relative;
  z-index: 500;

  img {
    width: 100%;
    height: 100%;
  }
  .large {
    position: absolute;
    top: 0;
    left: 422px;
    width: 600px;
    height: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 840px 840px; // 修改这里：420px * 3 = 1260px
    background-color: #f8f8f8;
    z-index: 999;
  }
  .middle {
    margin-bottom: 7px;
    width: 420px;
    height: 420px;
    background: #f5f5f5;
    position: relative;
    cursor: move;
    overflow: hidden;
    z-index: 9;
    .imageLayer{
      border-radius: 10px;
    }
    .layer {
      width: 200px;
      height: 200px;
      background: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      position: absolute;
    }
  }
}
.el-car-itembox {
  width: 100%;
  display: flex;
  height: 100px;
  .el-car-itembox-img{
    margin-right: 6.66px;
    height: 100px;
    width: 100px;
  }
  .el-car-itembox-img:last-child{
    margin-right: 0px;
  }
}
// ::v-deep .el-carousel__indicators--horizontal {
//   bottom: 15px;
// }
</style>

<template>
  <div class="footers">
    <div class="footerTop">
      <div class="footerTopLeft">
        <img src="../../assets/index/logi.png" class="logClass">
      </div>
      <div class="footerTopContent">
        <div v-for="(item,index) in menusList" :key="index" class="menusItem" @click="menusListClick(item,index)">
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
      <div class="footerTopRight">
        <div v-for="(item,index) in menusImage" :key="index" class="menusItem" @click="menusImaegClick(item)">
          <img :src="item.image" alt="" class="menusImageItem">
        </div>
      </div>
    </div>
    <div class="footerContent">
      <div class="contactInformationClass">
        <div class="titel">{{ $t("contactInformation") + ':' }}&nbsp;</div>
        <div class="text1">88002500810</div>
      </div>
      <div class="addressClass">
        <div class="titel">{{ $t("address") + ':' }}&nbsp;</div>
        <div class="text"> {{ $t("addressChina") }}&nbsp;;&nbsp;</div>
        <div class="text">{{ $t("addressRussian") }}</div>
      </div>
    </div>
    <div class="footerBottom">
      {{ $t("confidentialityPolicy") }}
    </div>
  </div>
</template>
  
  
<script setup>
import { ref, reactive, getCurrentInstance, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import whatsapp from "@/assets/index/whatsapp.png";
import telegram from "@/assets/index/telegram.png";
import vk from "@/assets/index/vk.png";
import youtube from "@/assets/index/youtube.png";
import twitter from "@/assets/index/twitter.png";
import facebook from "@/assets/index/facebook.png";
import instagram from "@/assets/index/instagram.png";
import yandex from "@/assets/index/yandex.png";
import tiktok from "@/assets/index/tiktok.svg"
const router = useRouter();
const $t = getCurrentInstance().appContext.config.globalProperties.$t;//引入语言

onMounted(() =>{//实例创建之后更新选择的菜单模块
 
})

let menusList = computed(() => {
  let list = reactive([
    {
      title : $t("privacyPolicy"),
      url: "/system/privacyAgreement",
    },
    {
      title : $t("userAgreement"),
      url: "/system/userAgreement",
    },
    {
      title : $t("aboutMEILIN"),
      url: "/system/aboutUs",
    },
    {
      title : $t("dealerWebsite"),
      url: "/store/dealer",
    },
    {
      title : $t("newsCenter"),
      url: "/store/news",
    },
    {
      title : $t("appDownload"),
      url: "/appDownload/appDownload",
    },
  ])
  return list
})

let menusImage = reactive([
  {
    image: whatsapp,
    url: "https://api.whatsapp.com/send/?phone=79939072477&text&type=phone_number&app_absent=0",
  },
  {
    image: telegram,
    url: "https://t.me/meilin_auto",
  },
  {
    image: vk,
    url: "https://vk.com/meilinauto",
  },
  {
    image: youtube,
    url: "https://www.youtube.com/@meilinauto",
  },
  {
    image: tiktok,
    url: "https://www.tiktok.com/@meilinauto?is_from_webapp=1&sender_device=pc",
  },
  {
    image: facebook,
    url: "",
  },
  {
    image: instagram,
    url: "https://www.instagram.com/meilin_auto?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
  },
  {
    image: yandex,
    url: "https://dzen.ru/meilin",
  },
])

const menusListClick = (item,index) => {
  if(item.url){
    router.push(item.url)
    if(index == 2){
      sessionStorage.setItem('activeIndex',4)
    } else if(index == 3){
      sessionStorage.setItem('activeIndex',5)
    } else if(index == 4){
      sessionStorage.setItem('activeIndex',6)
    }
  }
}

const menusImaegClick = (item) => {
  if(item.url){
    window.open(item.url)
  }
}
</script>
  
<style lang="scss" scoped>
.footers{
  background-color: #212121;
  padding-bottom: 14px;
  .footerTop{ 
    width: 100%;
    padding: 15px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #474444;
    .footerTopLeft{
      .logClass{
        width: 128px;
        height: 33px;
      }
    }
    .footerTopContent{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      margin: 0 10%;
      font-size: 12px;
      font-weight: 500;
      color: #D3D3D3;
      .menusItem{
        padding: 0 10px;
        .title{
          cursor: pointer;
        }
      }
      
    }
    .footerTopRight{
      display: flex;
      .menusItem{
        margin: 0 5px;
        .menusImageItem{
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
      }
    }
  }
  .footerContent{
    width: 100%;
    padding: 19px 5% 23px;
    .contactInformationClass,.addressClass{
      display: flex;
      align-items: center;
      .titel{
        font-size: 10px;
        font-weight: 400;
        color: #D3D3D3;
        width: fit-content;
        height: 14px;
      }
      .text{
        font-size: 10px;
        font-weight: 400;
        color: #7A7A7A;
      }
      .text1{
        font-size: 14px;
        font-weight: 400;
        color: #7A7A7A;
      }
    }
    .addressClass{
      margin-top: 4px;
    }
  }
  .footerBottom{
    height: 14px;
    font-size: 10px;
    font-weight: normal;
    color: #D3D3D3;
    text-align: center;
  }
}
</style>
  
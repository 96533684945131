/*
 * @Descripttion: 描述
 * @Author: 刘黎
 * @Date: 2024-01-31 19:42:07
 * @LastEditors: 刘黎
 * @LastEditTime: 2024-02-03 20:03:49
 */
import router from "@/router";
import { getApi } from "@/api/api.js"

// 白名单路由
// const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {
  const hasToken = localStorage.getItem("token");
  if (hasToken) {
    if (to.path === '/login') {
      // 如果已经登录,跳转到首页
      next({ path: '/' })
    } else {
      const hasMobile = localStorage.selectUserData ? JSON.parse(localStorage.selectUserData).id : '' // 缓存有用户信息则跳转没有则else获取用户信息
      if (hasMobile) {
        next()
      } else {
        // 获取用户信息
        await getApi('/user/selectUser').then(res => {
          if(res.code===200){
            localStorage.selectUserData = JSON.stringify(res.data)
          }
        }).catch(err => {})
        next()
      }
    }
  } else {
    // 未登录可以访问白名单页面
    next()
  }
});

router.afterEach(() => {
});
